import React from 'react';
import { Container, Typography, Box, Grid, Card, CardContent, Divider, Paper, Button, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const features = [
    { feature: "Access to Historical Congress Stock Trades", free: "Limited & Delayed", premium: true, developer: true },
    { feature: "Congressional Trading Leaderboard", free: "Limited", premium: true, developer: true },
    { feature: "Digest Emails", free: "Limited", premium: true, developer: true },
    { feature: "Access to Historical Congress Options Trades", free: false, premium: true, developer: true },
    { feature: "Individual Congress Member Trading Stats", free: false, premium: true, developer: true },
    { feature: "Stock Rankings Dashboard", free: false, premium: true, developer: true },
    { feature: "Full Data Exports (CSV, Excel, JSON)", free: false, premium: false, developer: true },
    { feature: "API Access", free: false, premium: false, developer: true },
];

const getIcon = (available) => {
    if (typeof available === 'boolean') {
        return available ? <CheckIcon style={{ color: 'green' }} /> : null;
    }
    return available;
};

const Pricing = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is small (mobile)

    return (
        <Container>
            <Box sx={{ paddingTop: 4 }}>
                <Typography variant="h4" align="center" gutterBottom sx={{ marginBottom: 3 }}>
                    Plans & Pricing
                </Typography>

                {/* Desktop View: Pricing Table */}
                {!isMobile && (
                    <TableContainer component={Paper}>
                        <Table aria-label="pricing table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ height: '50px' }}>
                                            <Typography variant="h6" sx={{ lineHeight: '50px' }}>Free</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'rgba(33,150,243,1)' }}>
                                        <Typography variant="h6">Premium</Typography>
                                        <Button variant="contained" color="primary" sx={{ marginTop: 1 }} component={Link} to="/account">
                                            Upgrade
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h6">Developer+</Typography>
                                        <Button variant="contained" color="primary" sx={{ marginTop: 1 }} disabled>
                                            Coming Soon
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5">$0 / month</Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{ backgroundColor: 'rgba(33,150,243,0.8)' }}>
                                        <Typography variant="h5">$8 / month</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="h5">$99 / month</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {features.map((row) => (
                                    <TableRow key={row.feature}>
                                        <TableCell component="th" scope="row">
                                            <Typography variant="body1">{row.feature}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            {getIcon(row.free)}
                                        </TableCell>
                                        <TableCell align="center" sx={{ backgroundColor: 'rgba(33,150,243,1)' }}>
                                            {getIcon(row.premium)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {getIcon(row.developer)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {/* Mobile View: Pricing Cards */}
                {isMobile && (
                    <Grid container spacing={3}>
                        {['free', 'premium', 'developer'].map((tier) => (
                            <Grid item xs={12} key={tier}>
                                <Card sx={{ padding: '20px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                    <CardContent>
                                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', textTransform: 'uppercase', color: '#555', textAlign: 'left' }}>
                                            {tier.charAt(0).toUpperCase() + tier.slice(1)}
                                        </Typography>
                                        <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', marginTop: '-10px', textAlign: 'left' }}>
                                            {tier === 'free' ? '$0' : tier === 'premium' ? '$8' : '$99'}
                                            <Typography variant="subtitle2" component="span" sx={{ marginLeft: 1, fontSize: '1rem', color: '#888' }}>
                                                / month
                                            </Typography>
                                        </Typography>
                                        <Divider sx={{ marginY: 2 }} />
                                        <Box sx={{ textAlign: 'left' }}>
                                            {features.map((row, idx) => (
                                                <Box key={idx} display="flex" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                                                    <Typography variant="body2" sx={{ flexGrow: 1 }}>{row.feature}</Typography>
                                                    <Typography variant="body2" sx={{ textAlign: 'right' }}>
                                                        {tier === 'free' && row.free ? row.free : getIcon(row[tier])}
                                                    </Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Button 
                                            variant="contained" 
                                            color="primary" 
                                            sx={{ marginTop: 2, width: '100%' }}
                                            component={Link}
                                            to="/account"
                                            disabled={tier === 'developer'}
                                        >
                                            {tier === 'developer' ? 'Coming Soon' : tier === 'free' ? 'Get Started' : 'Upgrade'}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
        </Container>
    );
};

export default Pricing;
