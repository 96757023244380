// src/pages/TradingLeaderboard.js

import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Switch,
    FormControlLabel
} from '@mui/material';
import { fetchAuthSession } from 'aws-amplify/auth';
import TraderCard from '../components/TraderCard';
import { useInView } from 'react-intersection-observer'; // Lazy loading and scroll into view detection

function TradingLeaderboard() {
    const [leaders, setLeaders] = useState([]);
    const [filteredLeaders, setFilteredLeaders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortBy, setSortBy] = useState('default');
    const [showActiveOnly, setShowActiveOnly] = useState(true);
    const [visibleItems, setVisibleItems] = useState(10); // Initially visible items
    const purchaseThreshold = 10;

    const { ref, inView } = useInView({
        threshold: 0.3, // Trigger loading more items when 30% of the ref element is in view
    });

    // Fetch session data on component mount
    useEffect(() => {
        const fetchSession = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    const response = await fetch('https://api.capitolgains.io/user/congress', {
                        headers: { Authorization: `Bearer ${idToken}` },
                    });

                    if (!response.ok) throw new Error('Network response was not ok');
                    const data = await response.json();
                    const enrichedLeaders = (data.trader_stats || []).map(member => {
                        const score = (member.avg_returns?.stocks?.purchase?.roi || 0) * 100 * (member.bullish_transactions?.accuracy || 0);
                        return { ...member, score };
                    });
                    setLeaders(enrichedLeaders);
                    setLoading(false);
                } else throw new Error('Session is invalid or missing ID token');
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchSession();
    }, []);

    // Apply sorting and filtering whenever the relevant state changes
    useEffect(() => {
        const getSortValue = (member) => {
            const avgReturns = member.avg_returns || {};
            const stocksReturns = avgReturns.stocks || {};
            const purchaseROI = stocksReturns.purchase ? stocksReturns.purchase.roi : 0;
            const purchaseCount = member.bullish_transactions?.count || 0;
            const accuracy = member.bullish_transactions?.accuracy || 0;
            const purchaseVolume = member.bullish_transactions?.volume || 0;
            const profitEstimated = member.bullish_transactions?.profit_estimated || 0;

            switch (sortBy) {
                case 'averageROI':
                    return purchaseROI;
                case 'hitRate':
                    return accuracy;
                case 'numberOfPurchases':
                    return purchaseCount;
                case 'averageStockPurchase':
                    return purchaseVolume;
                case 'estimatedProfit':
                    return profitEstimated;
                default:
                    return member.score;
            }
        };

        const filteredAndSortedLeaders = leaders
            .filter(member => showActiveOnly ? member.member_details?.current_member === true : true)
            .filter(member => member.bullish_transactions?.count >= purchaseThreshold)
            .sort((a, b) => getSortValue(b) - getSortValue(a));

        setFilteredLeaders(filteredAndSortedLeaders);
    }, [leaders, sortBy, showActiveOnly]);

    // Lazy load additional items when inView changes
    useEffect(() => {
        if (inView && visibleItems < filteredLeaders.length) {
            setVisibleItems(prevVisibleItems => prevVisibleItems + 10); // Load 10 more items
        }
    }, [inView, visibleItems, filteredLeaders.length]);

    if (loading) return <Container><Grid container justifyContent="center"><CircularProgress /></Grid></Container>;
    if (error) return <Container><Alert severity="error">Error: {error.message}</Alert></Container>;

    return (
        <Container>
            <Typography variant="h4" gutterBottom sx={{ color: '#ffffff' }}>Congressional Stock Trading Leaderboard</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <FormControl variant="outlined" sx={{ minWidth: 200 }}>
                    <InputLabel id="sort-by-label">Sort By</InputLabel>
                    <Select
                        labelId="sort-by-label"
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        label="Sort By"
                    >
                        <MenuItem value="default">Capitol Gains Score</MenuItem>
                        <MenuItem value="averageROI">Annual Return</MenuItem>
                        <MenuItem value="hitRate">Hit Rate</MenuItem>
                        <MenuItem value="numberOfPurchases">Number of Purchases</MenuItem>
                        <MenuItem value="averageStockPurchase">Average Stock Purchase</MenuItem>
                        <MenuItem value="estimatedProfit">Estimated Total Profit</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={<Switch checked={showActiveOnly} onChange={(e) => setShowActiveOnly(e.target.checked)} />}
                    label="Show Only Active Members"
                />
            </Box>
            <Grid container spacing={3}>
                {filteredLeaders.length === 0 ? (
                    <Grid item xs={12}>
                        <Alert severity="info">No data available.</Alert>
                    </Grid>
                ) : (
                    filteredLeaders.slice(0, visibleItems).map((member, index) => (
                        <Grid item xs={12} md={6} key={index}>
                            <TraderCard member={member} index={index} />
                        </Grid>
                    ))
                )}
            </Grid>
            {/* Trigger box for lazy loading more items */}
            {visibleItems < filteredLeaders.length && (
                <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    {/* Empty box to trigger lazy loading */}
                </Box>
            )}
        </Container>
    );
}

export default TradingLeaderboard;