import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, Link, CircularProgress } from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { fetchAuthSession } from 'aws-amplify/auth'; // Ensure you're importing correctly based on your setup
import { useNavigate } from 'react-router-dom';

const ManageSubscription = () => {
    const [billingHistory, setBillingHistory] = useState({ data: [] });
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [canceling, setCanceling] = useState(false);
    const [jwtToken, setJwtToken] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSessionAndData = async () => {
            try {
                const session = await fetchAuthSession();
                const token = session.tokens.idToken.toString(); // Use the ID token instead of the access token
                setJwtToken(token);

                const historyResponse = await fetch('https://api.capitolgains.io/user/billing-history', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                if (!historyResponse.ok) throw new Error('Failed to fetch billing history.');
                const historyData = await historyResponse.json();

                const subscriptionResponse = await fetch('https://api.capitolgains.io/user/subscription-details', {
                    headers: { 'Authorization': `Bearer ${token}` },
                });
                if (!subscriptionResponse.ok) throw new Error('Failed to fetch subscription details.');
                const subscriptionData = await subscriptionResponse.json();

                setBillingHistory(historyData.invoices || { data: [] });
                setSubscriptionDetails(subscriptionData);
            } catch (error) {
                setError('Failed to load subscription data.');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSessionAndData();
    }, []);

    const handleCancelSubscription = async () => {
        setCanceling(true);
        try {
            const response = await fetch('https://api.capitolgains.io/user/cancel-subscription', {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${jwtToken}` },
            });

            if (!response.ok) throw new Error('Failed to cancel subscription.');
            const result = await response.json();
            setSubscriptionDetails(result);

            // Instead of showing an alert, navigate to the account page and refresh session
            await fetchAuthSession({ forceRefresh: true });
            navigate('/account');
        } catch (error) {
            setError('Failed to cancel subscription.');
            console.error(error);
        } finally {
            setCanceling(false);
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                <CircularProgress />
            </Box>
        );
    }
    
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Container>
            <Box sx={{ paddingTop: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Manage Subscription
                </Typography>

                {subscriptionDetails ? (
                    <>
                        <Typography variant="h6" gutterBottom>
                            {subscriptionDetails.next_billing_date ? (
                                `Next Billing Date: ${new Date(subscriptionDetails.next_billing_date.replace(' ', 'T')).toLocaleDateString()}`
                            ) : (
                                'Your subscription has been canceled and no further billing will occur.'
                            )}
                        </Typography>

                        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                            Billing History
                        </Typography>

                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Billed To</TableCell>
                                        <TableCell>Created On</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {billingHistory.data.map(invoice => (
                                        <TableRow key={invoice.id}>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <WorkspacePremiumIcon style={{ color: 'gold', marginRight: 8 }} /> {/* Gold crown icon */}
                                                    <div>
                                                        <Typography variant="body2">Subscription purchase</Typography>
                                                        <Typography variant="caption">{invoice.number}</Typography>
                                                    </div>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{invoice.customer_email}</TableCell>
                                            <TableCell>{new Date(invoice.created * 1000).toLocaleDateString()}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                                    color={invoice.status === 'paid' ? 'success' : 'primary'}
                                                />
                                            </TableCell>
                                            <TableCell>${(invoice.amount_paid / 100).toFixed(2)}</TableCell>
                                            <TableCell>
                                                <Link href={invoice.invoice_pdf} target="_blank" rel="noopener">
                                                    View invoice
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCancelSubscription}
                            sx={{ mt: 4 }}
                            disabled={canceling}
                        >
                            {canceling ? <CircularProgress size={24} color="inherit" /> : 'Cancel Subscription'}
                        </Button>
                    </>
                ) : (
                    <Typography variant="h6" gutterBottom>
                        No active subscription found.
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default ManageSubscription;