import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid,
    Typography,
    CircularProgress,
    Alert,
    Tooltip,
    IconButton,
    Paper,
    CardContent,
    Box,
    Stack,
    Link,
    Avatar
} from '@mui/material';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Link as RouterLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer'; // Lazy loading and scroll into view detection
import { motion } from 'framer-motion'; // Import Framer Motion

// Helper to detect scroll speed
let lastScrollTop = 0;
let lastScrollTime = Date.now();

const getScrollSpeed = () => {
    const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const currentTime = Date.now();
    const timeDifference = currentTime - lastScrollTime;
    const scrollDistance = Math.abs(currentScrollTop - lastScrollTop);

    const speed = scrollDistance / timeDifference;

    lastScrollTop = currentScrollTop;
    lastScrollTime = currentTime;

    return speed;
};

// Create a new component for the stock card to use hooks properly
const StockCard = ({ stock, index, dynamicThreshold }) => {
    // Dynamic threshold based on scroll speed passed as a prop
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: dynamicThreshold, // Use dynamic threshold
    });

    // Dynamically adjust animation duration based on scroll speed
    const scrollSpeed = getScrollSpeed();
    const animationDuration = scrollSpeed > 0.5 ? 0.2 : 0.6; // Faster scroll, quicker animation

    const fadeInVariant = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: animationDuration, ease: 'easeInOut' },
        },
    };

    const scoreStyle = {
        color: stock.score >= 1 ? 'green' : 'red',
    };

    const formattedScore = stock.score >= 1 ? `+${(stock.score - 1).toFixed(2)}` : `${(stock.score - 1).toFixed(2)}`;
    const logoUrl = `https://img.logo.dev/ticker/${stock.ticker}?token=pk_Fy4ZV5RMQEKzNJfSy9h6BA`;

    return (
        <Grid item xs={12} md={6} key={index}>
            <motion.div
                ref={ref}
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInVariant}
            >
                <Paper elevation={3} sx={{ borderRadius: 2 }}>
                    <Box
                        sx={{
                            backgroundColor: '#1976d2',
                            padding: 2,
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar
                            src={logoUrl}
                            alt={`${stock.ticker} logo`}
                            sx={{ width: 50, height: 50, marginRight: 2 }}
                        />
                        <Box>
                            <Typography variant="h6" style={{ color: 'white' }}>
                                <Link
                                    href={`https://finance.yahoo.com/quote/${stock.ticker}`}
                                    target="_blank"
                                    rel="noopener"
                                    color="inherit"
                                    underline="hover"
                                    style={{ color: 'white' }}
                                >
                                    {`${stock.rank}. ${stock.ticker}`}
                                </Link>
                            </Typography>
                            <Typography variant="subtitle2" sx={scoreStyle} style={{ color: 'white' }}>
                                Score: {formattedScore}
                            </Typography>
                        </Box>
                    </Box>
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Congress Members Purchased:</strong> {stock.congress_members_purchased.length > 0 ? stock.congress_members_purchased.join(', ') : 'None'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Congress Members Sold:</strong> {stock.congress_members_sold.length > 0 ? stock.congress_members_sold.join(', ') : 'None'}
                            </Typography>
                        </Stack>
                    </CardContent>
                </Paper>
            </motion.div>
        </Grid>
    );
};

function StockRankings() {
    const [rankings, setRankings] = useState([]);
    const [loading, setLoading] = useState(true);  // Only shows for the initial load
    const [error, setError] = useState(null);
    const [visibleItems, setVisibleItems] = useState(10); // Number of initially visible items

    const scrollSpeed = getScrollSpeed();

    // Dynamic threshold based on scroll speed
    const dynamicThreshold = scrollSpeed > 0.5 ? 0.5 : 0.2;

    const { ref, inView } = useInView({ threshold: 0.3 }); // Adjusted threshold for quicker loading

    useEffect(() => {
        const fetchStockRankings = async () => {
            try {
                const session = await fetchAuthSession();
                if (session && session.tokens && session.tokens.idToken) {
                    const idToken = session.tokens.idToken.toString();
                    const response = await fetch('https://api.capitolgains.io/user/rankings', {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${idToken}`
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    if (data.rankings && Array.isArray(data.rankings)) {
                        setRankings(data.rankings);
                    } else {
                        throw new Error('Unexpected API response format');
                    }
                } else {
                    throw new Error('Session is invalid or missing ID token');
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);  // Stop showing loader after the initial fetch
            }
        };
        fetchStockRankings();
    }, []);

    useEffect(() => {
        if (inView && visibleItems < rankings.length) {
            setVisibleItems((prevVisibleItems) => prevVisibleItems + 20); // Load more items more aggressively
        }
    }, [inView, visibleItems, rankings.length]);

    if (loading) {
        return (
            <Container>
                <Grid container justifyContent="center">
                    <CircularProgress />  {/* Loader is only for the first API call */}
                </Grid>
            </Container>
        );
    }

    if (error) {
        return (
            <Container>
                <Alert severity="error">Error: {error.message}</Alert>
            </Container>
        );
    }

    return (
        <Container>
            {/* Added Disclaimer */}
            <Typography 
                variant="body2" 
                color="textSecondary" 
                gutterBottom
                sx={{
                    padding: '10px', 
                    backgroundColor: 'transparent', 
                    borderRadius: '4px', 
                    marginBottom: '20px',
                    border: '1px solid #666', 
                    color: '#bbb', 
                    textAlign: 'center', 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    fontSize: '0.75rem' // Smaller font size
                }}
            >
                Users are responsible for their own trading decisions, and DataLounge LLC is not liable for any financial outcomes resulting from the use of this information.<br/>
                These rankings are for informational purposes only and do not constitute financial advice.
            </Typography>
            
            <Grid container alignItems="center" spacing={2}> {/* Fixed vertical spacing */}
                <Grid item>
                    <Typography variant="h4" gutterBottom>
                        Stock Rankings
                    </Typography>
                </Grid>
                <Grid item>
                    <Tooltip
                        title={
                            <>
                                <Typography variant="body2" color="inherit">
                                    Learn more about how stock rankings are determined, including the factors that influence them.
                                </Typography>
                                <Link component={RouterLink} to="/stock-ranking-info" underline="hover" color="inherit">
                                    Click here for more details.
                                </Link>
                            </>
                        }
                    >
                        <IconButton>
                            <InfoOutlined />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            <Grid container spacing={3}> {/* Adjust the spacing between cards */}
                {rankings.slice(0, visibleItems).map((stock, index) => (
                    <StockCard key={index} stock={stock} index={index} dynamicThreshold={dynamicThreshold} />
                ))}
            </Grid>

            {/* Lazy loading the next items without the CircularProgress */}
            {visibleItems < rankings.length && (
                <Box ref={ref} sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    {/* No CircularProgress during lazy loading */}
                </Box>
            )}
        </Container>
    );
}

export default StockRankings;