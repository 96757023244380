// src/components/TraderCard.js

import React from 'react';
import { Box, Typography, Paper, Stack, Divider, Avatar, useMediaQuery } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from 'react-router-dom';
import InfoTooltip from './InfoTooltip';
import { useTheme } from '@mui/material/styles';

const TraderCard = ({ member, index }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        name,
        member_details = {}, // Default to an empty object to prevent errors
        avg_returns = {},
        bullish_transactions = {},
    } = member;

    const purchaseROI = avg_returns.stocks?.purchase?.roi || 0;
    const purchaseCount = bullish_transactions.count || 0;
    const accuracy = bullish_transactions.accuracy || 0;
    const purchaseVolume = bullish_transactions.volume || 0;
    const profitEstimated = bullish_transactions.profit_estimated || 0;

    const roiPercent = ((purchaseROI - 1) * 100).toFixed(2);
    const formattedVolume = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(purchaseVolume);
    const formattedProfitEstimated = profitEstimated
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(profitEstimated)
        : 'N/A';

    const memberImage = member_details?.image || 'https://via.placeholder.com/150';
    const memberParty = member_details?.party || 'Unknown Party';
    const memberState = member_details?.state || 'Unknown State';
    const currentMemberText = member_details?.current_member ? 'Yes' : 'No';
    const partyColor = memberParty === 'Democratic' ? '#1976d2' : memberParty === 'Republican' ? '#d32f2f' : '#757575';

    return (
        <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden', backgroundColor: '#333333' }}>
            {/* Header with Name, Party, and Rank */}
            <Box sx={{ padding: 2, backgroundColor: '#424242', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {isMobile && (
                    <Avatar src={memberImage} alt={`${name} profile`} sx={{ width: 56, height: 56, marginRight: 2 }} />
                )}
                <Box sx={{ flex: 1, textAlign: isMobile ? 'left' : 'inherit' }}>
                    <Typography variant="h6" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                        <Link to={`/congressmember/${member_details?.bioguideId || ''}`} style={{ color: '#ffffff', textDecoration: 'none' }}>
                            {name}
                        </Link>
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <CircleIcon sx={{ fontSize: 16, color: partyColor, marginRight: 0.6 }} />
                        <Typography variant="subtitle2" sx={{ color: '#ffffff' }}>
                            {memberParty} - {memberState}
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="h4" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
                    #{index + 1}
                </Typography>
            </Box>
            <Divider sx={{ borderColor: '#616161' }} />

            {/* Main Body with Rectangular Profile Image and Stats */}
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: 2, backgroundColor: '#333333' }}>
                {!isMobile && (
                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={memberImage}
                            alt={`${name} profile`}
                            sx={{
                                width: '100px',
                                height: '130px',
                                borderRadius: 1,
                                objectFit: 'cover'
                            }}
                        />
                    </Box>
                )}
                <Box sx={{ flex: 2, color: '#bdbdbd', paddingLeft: isMobile ? 0 : 2 }}>
                    <Stack spacing={1}>
                        <Typography variant="body2" sx={{ color: purchaseROI >= 1 ? '#76ff03' : '#ff1744' }}>
                            <strong>Average Annual Return:</strong> {roiPercent}%
                            <InfoTooltip title="The average annual percentage return of the investments made." />
                        </Typography>
                        <Typography variant="body2">
                            <strong>Hit Rate:</strong> {(accuracy * 100).toFixed(2)}%
                            <InfoTooltip title="The percentage of trades that were successful or profitable." />
                        </Typography>
                        <Typography variant="body2">
                            <strong>Number of Purchases:</strong> {purchaseCount}
                            <InfoTooltip title="The total number of stock purchases made." />
                        </Typography>
                        <Typography variant="body2">
                            <strong>Average Stock Purchase:</strong> {formattedVolume}
                            <InfoTooltip title="The average dollar amount spent per stock purchase." />
                        </Typography>
                        <Typography variant="body2">
                            <strong>Estimated Profit:</strong> {formattedProfitEstimated}
                            <InfoTooltip title="Estimated profit for the trader, assuming all assets were sold exactly one year after purchase." />
                        </Typography>
                        <Typography variant="body2">
                            <strong>Current Member:</strong> {currentMemberText}
                            <InfoTooltip title="Indicates whether the member is currently in office." />
                        </Typography>
                    </Stack>
                </Box>
            </Box>
        </Paper>
    );
};

export default TraderCard;